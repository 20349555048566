export default {
  STATUS_CREATED: 'created', // 新訂單
  STATUS_CHECKING: 'checking', // 確認中
  STATUS_PREPARE: 'prepare', // 理貨
  STATUS_CANCEL_REQUEST: 'cancel_request', // 申請取消中
  STATUS_CANCEL_APPLY: 'cancel_apply', // 申請取消成功
  STATUS_SHIPPING: 'shipping', // 配送中
  STATUS_DELIVERED: 'delivered', // 已送達
  STATUS_RETURN_REQUEST: 'return_request', // 申請退貨中
  STATUS_RETURN_APPLY: 'return_apply', // 申請退貨成功(處理退貨作業中)
  STATUS_VOID: 'void', // 作廢
  STATUS_FINISHED: 'finished', // 完成
  STATUS_FAILURE: 'failure', // 失敗
}
