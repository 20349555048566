import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import accountingConstants from 'modules/accounting/config/accountingConstants'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'accounting'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$accoutingConstants = accountingConstants
    Vue.prototype.$accoutingTypes = [
      accountingConstants.TYPE_REVENUE,
      accountingConstants.TYPE_COST,
      accountingConstants.TYPE_OFFSET
    ]
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/accounting/config/menu'),
      realApi: () => import('modules/accounting/config/realApi'),
      routes: () => import('modules/accounting/config/route'),
    }
  }
}

export default new moduleIndex()
