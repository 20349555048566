import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import orderConstants from 'modules/order/config/orderConstants'
import paymentConstants from 'modules/order/config/paymentConstants'
import deliveryConstants from 'modules/order/config/deliveryConstants'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'order'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$orderSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('module.order'),
        bodySlot: () => import('modules/order/selector/orderList.vue'),
      })
    }

    Vue.prototype.$orderConstants = orderConstants
    Vue.prototype.$orderTypes = [
      orderConstants.STATUS_CREATED,
      orderConstants.STATUS_CHECKING,
      orderConstants.STATUS_PREPARE,
      orderConstants.STATUS_CANCEL_REQUEST,
      orderConstants.STATUS_CANCEL_APPLY,
      orderConstants.STATUS_RETURN_REQUEST,
      orderConstants.STATUS_RETURN_APPLY,
      orderConstants.STATUS_SHIPPING,
      orderConstants.STATUS_VOID,
      orderConstants.STATUS_FINISHED,
      orderConstants.STATUS_FAILURE,
    ]

    Vue.prototype.$paymentConstants = paymentConstants
    Vue.prototype.$paymentStatus = [
      paymentConstants.STATUS_CREATED,
      paymentConstants.STATUS_PROCESSING,
      paymentConstants.STATUS_UPDATE_INFO,
      paymentConstants.STATUS_UNPAID,
      paymentConstants.STATUS_PAID,
      paymentConstants.STATUS_FAILURE,
    ]
    Vue.prototype.$paymentStatusEditable = [
      paymentConstants.STATUS_UNPAID,
      paymentConstants.STATUS_PAID,
      paymentConstants.STATUS_FAILURE,
    ]
    Vue.prototype.$deliveryConstants = deliveryConstants
    Vue.prototype.$deliveryStatus = [
      deliveryConstants.STATUS_CREATED,
      deliveryConstants.STATUS_PROCESSING,
      deliveryConstants.STATUS_REQUEST_TO_PROVIDER,
      deliveryConstants.STATUS_CHECKING,
      deliveryConstants.STATUS_PREPARE,
      deliveryConstants.STATUS_SHIPPING,
      deliveryConstants.STATUS_IN_CVS,
      deliveryConstants.STATUS_DELIVERED,
      deliveryConstants.STATUS_EXPIRED,
      deliveryConstants.STATUS_OTHERS,
    ]

    Vue.prototype.$deliveryTypeEditable = [
      deliveryConstants.STATUS_CHECKING,
      deliveryConstants.STATUS_SHIPPING,
      deliveryConstants.STATUS_IN_CVS,
      deliveryConstants.STATUS_DELIVERED,
      deliveryConstants.STATUS_EXPIRED,
    ]

    Vue.prototype.$deliveryTemperature = [
      deliveryConstants.TEMPERATURE_NORMAL,
      deliveryConstants.TEMPERATURE_REFRIGERATION,
      deliveryConstants.TEMPERATURE_FREEZING,
    ]

    Vue.prototype.$deliveryPeriod = [
      deliveryConstants.PERIOD_MORNING,
      deliveryConstants.PERIOD_AFTERNOON,
      deliveryConstants.PERIOD_ANYTIME,
    ]

    Vue.prototype.$deliverySizeOptions = [60, 90, 120, 150]

    Vue.prototype.$isCvsDeliveryType = (deliveryType: string) : boolean => {
      return vm.$store.getters['base/feature'].cvsDeliveryTypes[deliveryType] === true
    }

    Vue.prototype.$isHomeDeliveryType = (deliveryType: string) : boolean => {
      return vm.$store.getters['base/feature'].homeDeliveryTypes[deliveryType] === true
    }
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/order/config/menu'),
      realApi: () => import('modules/order/config/realApi'),
      routes: () => import('modules/order/config/route'),
    }
  }
}

export default new moduleIndex()
