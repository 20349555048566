export default {
  // 物流狀態
  STATUS_CREATED: 'created', // 新建立
  STATUS_PROCESSING: 'processing', // 物流機制處理中(只要物流模組一接手就設定此欄位, 代表物流組有接手到)
  STATUS_REQUEST_TO_PROVIDER: 'request_to_provider', // 已成功向第三方物流服務發出請求
  STATUS_CHECKING: 'checking', // 確認中
  STATUS_PREPARE: 'prepare', // 理貨
  STATUS_SHIPPING: 'shipping', // 配送中
  STATUS_IN_CVS: 'in_cvs', // 已送達超商門市
  STATUS_DELIVERED: 'delivered', // 已送達(消費者已由超商門市取件/宅配完成/完成面交)
  STATUS_EXPIRED: 'expired', // 消費者超過時效未取件
  STATUS_OTHERS: 'others', // 其他情況(第三方物流情況過多, 因此非上述狀態皆為其他)

  // 寄送類型
  TYPE_FACE_TO_FACE: 'face_to_face', // 面交(非第三方)
  TYPE_HOME_TCAT: 'home_tcat', // 宅配-黑貓
  TYPE_HOME_ECAN: 'home_ecan', // 宅配-宅配通
  TYPE_CVS_SEVEN: 'cvs_seven', // 7-11
  TYPE_CVS_FAMI: 'cvs_fami', // 全家
  TYPE_CVS_HILIFE: 'cvs_hilife', // 萊爾富
  TYPE_CVS_OK: 'cvs_ok', // OK

  // 物流廠商
  PROVIDER_DEFAULT: 'default', // 預設
  PROVIDER_ECPAY: 'ecpay', // 綠界

  // 操作人員類型
  OPERATOR_TYPE_SYSTEM: 'system',
  OPERATOR_TYPE_SITE_ADMIN: 'site_admin',
  OPERATOR_TYPE_MEMBER: 'member',
  OPERATOR_TYPE_PROVIDER: 'provider',

  // 配送溫度
  TEMPERATURE_NORMAL: 'normal',
  TEMPERATURE_REFRIGERATION: 'refrigerator',
  TEMPERATURE_FREEZING: 'freezing',

  // 送達時段
  PERIOD_MORNING: 'morning',
  PERIOD_AFTERNOON: 'afternoon',
  PERIOD_ANYTIME: 'anytime',
}
