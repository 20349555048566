export default {
  // 付款狀態
  STATUS_CREATED: 'created', // 新建立
  STATUS_PROCESSING: 'processing', // 付款處理中(只要付款模組一接手就設定此欄位, 代表付款模組有接手到)
  STATUS_UPDATE_INFO: 'update_info', // 已取號(ATM/超商代碼/超商條碼專用, 只會一瞬間有此狀態, 單純留紀錄用)
  STATUS_UNPAID: 'unpaid', // 尚未付款
  STATUS_PAID: 'paid', // 已付款(第三方金流付款成功/後台直接設定為確認付款成功)
  STATUS_FAILURE: 'failure', // 付款失敗

  // 付款方式
  TYPE_ATM: 'atm', // ATM
  TYPE_WEB_ATM: 'web_atm', // Web ATM
  TYPE_CREDIT: 'credit', // 信用卡
  TYPE_CVS_CODE: 'cvs_code', // 超商代碼
  TYPE_CVS_BARCODE: 'cvs_barcode', // 超商條碼
  TYPE_CASH_ON_DELIVERY: 'cash_on_delivery', // 貨到付款

  // 金流廠商
  PROVIDER_DEFAULT: 'default', // 預設
  PROVIDER_ECPAY: 'ecpay', // 綠界
  PROVIDER_QPAY: 'qpay', // 永豐豐收款

  // 操作人員類型
  OPERATOR_TYPE_SYSTEM: 'system',
  OPERATOR_TYPE_SITE_ADMIN: 'site_admin',
  OPERATOR_TYPE_MEMBER: 'member',
  OPERATOR_TYPE_PROVIDER: 'provider',
}
