const paymentConstants = require('./site/paymentConstants') // eslint-disable-line
const deliveryConstants = require('./site/deliveryConstants') // eslint-disable-line
module.exports = {
  name: 'Higher EC',
  manifest: {
    themeColor: '#1190cb',
    backgroundColor: '#000000',
  },
  locales: {
    list: ['zh-TW', 'zh-CN', 'en-US'],
    defaultLocale: 'zh-TW',
    defaultTimeZone: 'Asia/Taipei',
  },
  seo: {
    keywords: 'Higher EC',
    description: `Tech make the world rising high`,
  },
  sitemap: {
    types: [
      'page',
    ],
  },
  sms: {
    service: 'twilio',
    global: true,
  },
  feature: {
    /**
     * 各頁面的階層設定(頁面index sidebar使用)
     */
    pageTypes: {
      default: { depth: 1 },
      article: { depth: 2 },
      project: { depth: 1 },
    },

    // 商品分類最多階層數
    productCategoryMaxDepth: 2,

    // SMS功能
    smsModule: true,

    // 是否使用第三方物流服務
    ecThirdPartyDeliveryService: true,

    // 所有金流類型
    allPaymentTypes: [
      paymentConstants.TYPE_ATM,
      paymentConstants.TYPE_WEB_ATM,
      paymentConstants.TYPE_CREDIT,
      paymentConstants.TYPE_CVS_CODE,
      paymentConstants.TYPE_CVS_BARCODE,
      paymentConstants.TYPE_CASH_ON_DELIVERY,
    ],

    // 所有物流類型
    allDeliveryTypes: [
      deliveryConstants.TYPE_FACE_TO_FACE,
      deliveryConstants.TYPE_HOME_TCAT,
      deliveryConstants.TYPE_HOME_ECAN,
      deliveryConstants.TYPE_CVS_SEVEN,
      deliveryConstants.TYPE_CVS_FAMI,
      deliveryConstants.TYPE_CVS_HILIFE,
      deliveryConstants.TYPE_CVS_OK,
    ],

    // 第三方物流類型
    thirdPartyDeliveryTypes: {
      [deliveryConstants.TYPE_HOME_TCAT]: true,
      [deliveryConstants.TYPE_HOME_ECAN]: true,
      [deliveryConstants.TYPE_CVS_SEVEN]: true,
      [deliveryConstants.TYPE_CVS_FAMI]: true,
      [deliveryConstants.TYPE_CVS_HILIFE]: true,
      [deliveryConstants.TYPE_CVS_OK]: true,
    },

    // 超商類型物流
    cvsDeliveryTypes: {
      [deliveryConstants.TYPE_CVS_SEVEN]: true,
      [deliveryConstants.TYPE_CVS_FAMI]: true,
      [deliveryConstants.TYPE_CVS_HILIFE]: true,
      [deliveryConstants.TYPE_CVS_OK]: true,
    },

    // 宅配類型物流
    homeDeliveryTypes: {
      [deliveryConstants.TYPE_HOME_TCAT]: true,
      [deliveryConstants.TYPE_HOME_ECAN]: true,
    },

    /**
     * 客服模組
     */
    contactModule: true,

    /**
     * 訂單模組
     */
    orderModule: true,

    /**
     * 帳務模組
     */
    accountingModule: true,
  },
}
