export default {
  'page.accounting-overview': '帳務總覽',
  'page.accounting': '帳務',
  'module.accounting': '帳務',

  // role
  'ROLE_ACCOUNTING': '帳務管理',

  // data
  'accounting.data.type': '帳務類型',
  'accounting.data.type.revenue': '收入',
  'accounting.data.type.revenue.help': '金額必須為大於零之正數',
  'accounting.data.type.cost': '支出',
  'accounting.data.type.cost.help': '金額必須為大於零之正數',
  'accounting.data.type.offset': '沖銷',
  'accounting.data.type.offset.help': '金額必須可為正數或負數，不可為零',
  'accounting.data.processed': '已處理',
  'accounting.data.processed.help': '已入帳或已結清',
  'accounting.data.order_id': '關聯的訂單編號',
  'accounting.data.order_id.help': '由該訂單產生的帳務',
  'accounting.data.order_link': '訂單內頁連結',

  'accounting.data.total_amount': '總金額',
  'accounting.data.processed_total_amount': '已處理總金額',
  'accounting.data.unprocessed_total_amount': '未處理總金額',

  'accounting.data.revenue_amount': '收入金額',
  'accounting.data.processed_revenue_amount': '已入帳收入金額',
  'accounting.data.unprocessed_revenue_amount': '未入帳收入金額',

  'accounting.data.cost_amount': '支出金額',
  'accounting.data.processed_cost_amount': '已結清支出金額',
  'accounting.data.unprocessed_cost_amount': '未結清支出金額',

  'accounting.data.offset_amount': '沖銷金額',
  'accounting.data.processed_offset_amount': '已處理沖銷金額',
  'accounting.data.unprocessed_offset_amount': '未處理沖銷金額',

  // form
  // 'accounting.form_block.foobar': 'foobar',

  // others
  'accounting.chart_action.show_total_amount': '顯示當日總金額',
  'accounting.action.set_processed_finished': '設定為已處理',
  'accounting.action.set_processed_unfinished': '設定為未處理',
  'accounting.search_keyword': '輸入訂單編號、名稱、敘述、備註搜尋',
}
